import styled from 'styled-components'
import { PageSection } from '@donaswap/uikit'
import { useAccount } from 'wagmi'
import Container from 'components/Layout/Container'
// import { PageMeta } from 'components/Layout/Page'
import { useActiveChainId } from 'hooks/useActiveChainId'
import Hero from './components/Hero'
import UserBanner from './components/UserBanner'
import RadialGradientByChainUpdater from '../../../../../packages/ui/tokens/RadialGradientByChainUpdater'
// import { useTheme } from '@donaswap/hooks'
// import Friends from './components/Friends'

// import useTheme from 'hooks/useTheme'
// import { useTranslation } from '@donaswap/localization'
// import { ChainId } from '@donaswap/sdk'
// import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
// import MetricsSection from './components/MetricsSection'
// import SalesSection from './components/SalesSection'
// import WinSection from './components/WinSection'
// import FarmsPoolsRow from './components/FarmsPoolsRow'
// import Footer from './components/Footer'
// import FlameDataRow from './components/FlameDataRow'
// import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
// import MultipleBanner from './components/Banners/MultipleBanner'

const StyledHeroSection = styled(PageSection)`
  padding-top: 10px;
  min-height: 80vh;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }

  @media (max-width: 768px) {
    min-height: 100vh; /* Ensure full-screen height on mobile devices */
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  // const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  // const { t } = useTranslation()

  return (
    <>
      {/* <PageMeta /> */}
      <style jsx global>{`
          #home-1 .page-bg {
            background: ${RadialGradientByChainUpdater(chainId)};
          }
          [data-theme='dark'] #home-1 .page-bg {
            background: ${RadialGradientByChainUpdater(chainId)};
          }
          [data-theme='fire'] #home-1 .page-bg {
            background: ${RadialGradientByChainUpdater(chainId)};
          }
          #home-2 .page-bg {
            background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);
          }
          [data-theme='dark'] #home-2 .page-bg {
            background: linear-gradient(180deg, #09070c 22%, #201335 100%);
          }
          #home-3 .page-bg {
            background: linear-gradient(180deg, #6fb6f1 0%, #eaf2f6 100%);
          }
          [data-theme='dark'] #home-3 .page-bg {
            background: linear-gradient(180deg, #0b4576 0%, #091115 100%);
          }
          #home-4 .inner-wedge svg {
            fill: #d8cbed;
          }
          [data-theme='dark'] #home-4 .inner-wedge svg {
            fill: #201335;
          }
        `}
      </style>
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%', minHeight: '80vh', backgroundImage: RadialGradientByChainUpdater(chainId) } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        {account && chainId && (
        // {account && chainId === ChainId.BSC && (
          <UserBannerWrapper>
            <UserBanner />
          </UserBannerWrapper>
        )}
        {/* <MultipleBanner /> */}
        <Hero />
        {/* <Friends /> */}
      </StyledHeroSection>
      {/* <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        containerProps={{
          id: 'home-4',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper top>
            <WedgeTopLeft />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...swapSectionData(t)} />
      </PageSection> */}

      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <WinSection />
      </PageSection> */}
      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...cakeSectionData(t)} />
        <FlameDataRow />
      </PageSection> */}
      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="linear-gradient(180deg, #000000 0%, #000000 100%)"
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection> */}
    </>
  )
}

export default Home
