import useSWRImmutable from 'swr/immutable'
import { NO_PROXY_CONTRACT } from 'config/constants'
import { useBFlameFarmBoosterContract } from 'hooks/useContract'
import { FetchStatus } from 'config/constants/types'
import { Address } from 'wagmi'
import { bFlameSupportedChainId } from '@donaswap/farms'

export const useBFlameProxyContractAddress = (account?: Address, chainId?: number) => {
  const bFlameFarmBoosterContract = useBFlameFarmBoosterContract()
  const isSupportedChain = chainId ? bFlameSupportedChainId.includes(chainId) : false
  const { data, status, mutate } = useSWRImmutable(
    account && isSupportedChain && ['bProxyAddress', account, chainId],
    async () => bFlameFarmBoosterContract.read.proxyContract([account]),
  )
  const isLoading = isSupportedChain ? status !== FetchStatus.Fetched : false

  return {
    proxyAddress: data as Address,
    isLoading,
    proxyCreated: data && data !== NO_PROXY_CONTRACT,
    refreshProxyAddress: mutate,
  }
}
