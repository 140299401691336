import React from "react";
// import { useTheme } from "styled-components";
// import Svg from "../Svg";
import { SvgProps } from "../types";

// const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
const Icon: React.FC<React.PropsWithChildren<SvgProps>> = () => {

  // const theme = useTheme();
  // const primaryColor = theme.isDark ? "#3C3742" : "#e9eaeb";/
  // const secondaryColor = theme.isDark ? "#666171" : "#bdc2c4";

  return (
      <img src="images/questionKnight.png" alt="" />
  );
};

export default Icon;
